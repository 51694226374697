import LandingPageSmall from "../components/LandingPageSmall";
import support from "../assets/images/support.jpg";
import { Grid } from "@mui/material";
import RoofSeriesAdvantageInside from "../components/RoofSeriesAdvantageInside";
import "../assets/css/Support.css"

const Support = () => {
  return (
   <div className="home-container">
      <LandingPageSmall
        landingImg={support}
        heading="THE ROOFINGSERIES ADVANTAGE"
      />
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{
          display: "flex",
        }}
        className="support-content">
        <Grid item xs={12} md={11}>
          <p style={{ textAlign: "center", marginBottom: "2%" }}>
          For over 30 years, the North American modular manufacturing industry has trusted SBS Inc. to supply its factories and remote job sites with products to protect their investments and assets from the outside in.  RoofingSeries focuses on providing PVC and EPDM roofing solutions.  When installed properly, these membranes, accessories, and site tie in materials, create watertight roofing systems that will perform for many years.  To support the modular manufacturing industry specifically, we custom cut and thermally heat weld to exact specifications enabling custom, just-in-time manufacturing and delivery. RoofingSeries PVC membranes are the only product on the market to utilize spreadcoat technology, eliminating the risk of delamination.Both PVC and EPDM offerings come with membrane warranties.
          </p>
        </Grid>
      </Grid>
      <RoofSeriesAdvantageInside />
      </div>
  );
};

export default Support;
