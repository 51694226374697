import React, { useState } from "react";
import { Tabs, Tab, Box, Grid } from "@mui/material";
import "../assets/css/Product.css";
import product1 from "../assets/images/product-1.jpg";
// import product2 from "../assets/images/product-2.jpg";
import product3 from "../assets/images/product-3.jpg";
import product4 from "../assets/images/product-4.jpg";
import product5 from "../assets/images/product-5.jpg";
import product6 from "../assets/images/product-6.jpg";
import product7 from "../assets/images/product-7.jpg";
import product8 from "../assets/images/product-8.jpg";
import product9 from "../assets/images/product-9.jpg";
import product10 from "../assets/images/product-10.jpg";
import { Link } from "react-router-dom";

const Products = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="home-container">
        <Grid container className="landing-container">
          <Grid item xs={12}>
            <div className="image-container">
              <img src={product1} alt="landingimage" />
              <div className="overlay"></div>
              <div className="product-text">
                <h4>THE WORLDS TOUGHEST MEMBRANES</h4>
                <p>
                  Born from a proven formula of the highest quality polymers and
                  packed with the most plasticizers, this product’s integrity is
                  unmatched.
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          gap={1}
        >
           <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#ffc600",
            },
          }}>
          <Tab
            label={
              value === 0 ? (
                "PVC Membranes"
              ) : (
                <h6>
                  PVC Membranes
                  <br />
                  Click for Info
                </h6>
              )
            }
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#ffc600",
                color: "#5a6771",
              },
              "&:not(.Mui-selected)": {
                backgroundColor: "#3e3a35",
                color: "#fff",
                fontSize:"18px"
              },
            }}
          />

          <Tab
            label={
              value === 1 ? (
                "EPDM Membranes"
              ) : (
                <h6>
                  EPDM Membranes
                  <br />
                  Click for Info
                </h6>
              )
            }
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#ffc600",
                color: "#5a6771",
              },
              "&:not(.Mui-selected)": {
                backgroundColor: "#3e3a35",
                color: "#fff",
                fontSize:"18px"
              },
            }}
          />
        </Tabs>
        </Box>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className="product-content"
        >
          <Grid item xs={12} md={11}>
            <TabPanel value={value} index={0}>
              <h1>PVC MODULAR ROOFING SOLUTIONS</h1>
              <p>
              RoofingSeries PVC membranes are supplied exclusively to the modular industry in North America by us at SBS Inc. Definitively,
                we have provided the highest quality PVC roofing membranes
                available to the modular manufacturing over the past 3 decades
                coupled with over 50 years of proven success with the membrane.
              </p>
              <p>
                RoofingSeries PVC systems are specifically engineered to meet
                the needs of modular manufacturers. The membranes are available
                in custom widths and lengths. Membranes and complimentary
                accessories are lightweight and easy to install.
              </p>
              <p>
              RoofingSeries PVC membranes meet or exceed the requirements of all relevant roofing standards.
              </p>
              <h2>ROOFINGSERIES PVC SYSTEM BENEFITS:</h2>
              <ul>
                <li>
                  Class ‘C’ Fire Rating fully adhered directly over min. 5/8"
                  T&G CDX Plywood
                </li>
                <li>Completely customizable roof sizes</li>
                <li>Over 30 years of proven performance to the modular industry</li>
                <li>Smooth backed; internally reinforced</li>
                <li>
                Manufactured via spread coating process, creating a true monolithic sheet
                </li>
                <li>
                  Thickness Options: 48mils (10year), 60mils (15year), 80mils
                  (20 year)
                </li>
                <li>
                  Full line of existing accessories and option for customizable
                  accessories
                </li>
              </ul>
              <h1>ROOFINGSERIES PVC MEMBRANES</h1>
              <p>
                RoofingSeries PVC Membranes have been designed to fit the needs
                of the modular industry in regards to flat roofing system
                solutions. Born from a proven formula of the highest quality
                polymers and packed with the most plasticizers, this product’s
                integrity is unmatched. It is a heat-weldable membrane produced
                with an internal fiberglass mat reinforcement for excellent
                dimensional stability. Once joined, the seam can withstand
                greater stresses than the sheet itself. Various thicknesses and
                the original fully walkable version, all provide incredible
                performance.
              </p>
              <Box className="container">
                <Link to="/products/pvc-membranes/membranes/dectec-r12000">
                  <div className="imageContainer" style={{backgroundImage:`url(${product1})`, backgroundPosition:"right center", backgroundSize:"cover" }}>
                    <div className="glass-overlay"></div>
                    <div className="centeredText-img">
                      R12000 / R15000 / R20000
                    </div>
                  </div>
                </Link>
                {/* <Link to="/products/pvc-membranes/membranes/dectec-reverse-r15000"> */}
                  {/* <div className="imageContainer" style={{backgroundImage:`url(${product2})`, backgroundPosition:"right center", backgroundSize:"cover" }}>
                    <div className="glass-overlay"></div>
                    <div className="centeredText-img">
                      Fully Walkable R15000
                    </div>
                  </div> */}
                {/* </Link> */}
              </Box>
              <h1>ADHESIVES & SEALANTS</h1>
              <Box className="container">
                <Link to="/products/pvc-membranes/adhesives-sealants/wba-200">
                  <div className="imageContainer" style={{backgroundImage:`url(${product3})`, backgroundPosition:"right center", backgroundSize:"cover" }}>
                    <div className="glass-overlay"></div>
                    <div className="centeredText-img">PVC WBA 200</div>
                  </div>
                </Link>
              </Box>
              <h1>ACCESSORIES</h1>
              <p>
                To finish certain details and ensure water tightness, some
                accessories are required. These include but are not limited to
                RoofingSeries compatible silicone caulking for sealing around
                metal flanges, pipe clamps to be used around ABS pipe
                protrusions, and butyl tape used under flanges and around
                perimeter terminations. Also an aluminum termination bar is used
                to securely fasten the membrane at the roof edge.
              </p>
              <Box className="container">
                <Link to="/products/pvc-membranes/accessories/dectec-r12000-pvc-boot-1-6-opening-conical">
                  <div className="imageContainer" style={{backgroundImage:`url(${product4})`, backgroundPosition:"right center", backgroundSize:"cover" }}>
                    <div className="glass-overlay"></div>
                    <div className="centeredText-img">
                      RoofingSeries Pipeboots
                    </div>
                  </div>
                </Link>
                <Link to="/products/pvc-membranes/accessories/termination-bar">
                  <div className="imageContainer" style={{backgroundImage:`url(${product5})`, backgroundPosition:"right center", backgroundSize:"cover" }}>
                    <div className="glass-overlay"></div>
                    <div className="centeredText-img">Termination Bar</div>
                  </div>
                </Link>
                <Link to="/products/pvc-membranes/accessories/descupper">
                  <div className="imageContainer" style={{backgroundImage:`url(${product6})`, backgroundPosition:"right center", backgroundSize:"cover" }}>
                    <div className="glass-overlay"></div>
                    <div className="centeredText-img">Dec-Scupper</div>
                  </div>
                </Link>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <Link to="/products/pvc-membranes/accessories/deccorner-outside">
                  <div className="imageContainer" style={{backgroundImage:`url(${product7})`, backgroundPosition:"right center", backgroundSize:"cover" }}>
                    <div className="glass-overlay"></div>
                    <div className="centeredText-img">Dec-Corner – Outside</div>
                  </div>
                </Link>
                <Link to="/products/pvc-membranes/accessories/deccorner-inside">
                  <div className="imageContainer" style={{backgroundImage:`url(${product8})`, backgroundPosition:"right center", backgroundSize:"cover" }}>
                    <div className="glass-overlay"></div>
                    <div className="centeredText-img">Dec-Corner – Inside</div>
                  </div>
                </Link>
                <Link to="/products/pvc-membranes/accessories/heat-welder">
                  <div className="imageContainer" style={{backgroundImage:`url(${product9})`, backgroundPosition:"right center", backgroundSize:"cover" }}>
                    <div className="glass-overlay"></div>
                    <div className="centeredText-img">Heat Welder</div>
                  </div>
                </Link>
              </Box>
            </TabPanel>
            {/* tab 2 */}
            <TabPanel value={value} index={1}>
              <h1>EPDM MODULAR ROOFING SOLUTIONS</h1>
              <p>
                EPDM (Ethylene Propylene Diene Monomer) modular roofing systems
                install quickly and economically. UL and FM listed assemblies
                are available upon request. Non-penetrating mate-line and
                bridging system keeps dealer multi-unit lease-fleets fit. EPDM
                membranes are fully adhered with Water Based Bonding Adhesive, a
                must for in-plant installation. Its single-side, wet application
                requires fewer workers and offers easier sheet positioning.
              </p>
              <p>
                All the accessories you need to complete roof details around
                edges and penetrations are also stocked locally for delivery
                along with your EPDM. EPDM is lightweight, making it ideal for
                new construction and retrofit installations without adding
                excessive weight to the roof deck. EPDM helps reduce the noise
                made by rain and hail or wind as compared to other roof systems
                such as metal roofs causing vibration. EPDM stretches to 425% so
                structural movement has no effect on integrity. It provides
                excellent resistance to ozone and UV and can handle the coldest
                winters or the desert heat.
              </p>
              <p>
                Meets or exceeds ASTM standards.EPDM membranes from
                RoofingSeries.
              </p>
              <h1>EPDM SYSTEM BENEFITS:</h1>
              <ul>
                <li>Easy to install and Retrofit</li>
                <li>Fast Roofs</li>
                <li>
                  High tensile strength, single-ply membrane ideal for long-term
                  watertight integrity
                </li>
                <li>
                  Quiet Protection eliminates “roof rumble” while it protects
                  against leaks
                </li>
                <li>
                  Highly flexible in hot and cold temperatures, will not split
                  or crack
                </li>
                <li>Long Lifecycle</li>
                <li>Non-reinforced and pliable</li>
                <li>
                  Membrane to membrane utilizes glues, tapes and adhesives
                </li>
                <li>45 mils thick with a 10 year warranty</li>
              </ul>
              <h1>ROOFINGSERIES EPDM MEMBRANES</h1>
              <p>
                EPDM Modular Roofing Systems utilize a versatile rubber roofing
                membrane that has been used in the low-slope roofing industry
                for over half a century. EPDM membranes install easily, have
                superior weatherability, outstanding aging, extreme flexibility
                and more.
              </p>
              <Box className="container">
                <Link to="/products/epdm-membranes/membranes/white-black">
                  <div className="imageContainer" style={{backgroundImage:`url(${product10})`, backgroundPosition:"right center", backgroundSize:"cover" }}>
                    <div className="glass-overlay"></div>
                    <div className="centeredText-img">White / Black EPDM</div>
                  </div>
                </Link>
              </Box>
              <h1>ADHESIVES & SEALANTS</h1>
              <Box className="container">
                <Link to="/products/epdm-membranes/adhesives-sealants/sbs-epdm-wba">
                  <div className="imageContainer" style={{backgroundImage:`url(${product3})`, backgroundPosition:"right center", backgroundSize:"cover" }}>
                    <div className="glass-overlay"></div>
                    <div className="centeredText-img">EPDM WBA</div>
                  </div>
                </Link>
              </Box>
            </TabPanel>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

export default Products;
