import { Box } from "@mui/system";
import LandingPageSmall from "../components/LandingPageSmall";
import { Grid } from "@mui/material";
import "../assets/css/Header.css";
import support4 from "../assets/images/support4.jpg";
import { Link } from "react-router-dom";
import RoofSeriesAdvantage from "../components/RoofSeriesAdvantage";
import firstWarranty from "../assets/pdf/R12000-10yr.pdf"
import secondWarranty from "../assets/pdf/R15000-15yr.pdf"
import thirdWarranty from "../assets/pdf/R20000-20yr.pdf"

const MaintenanceWarranty = () => {
  return (
    <div className="home-container">

      <LandingPageSmall
        landingImg={support4}
        heading="MAINTENANCE & WARRANTY"
      />

      <RoofSeriesAdvantage />

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{marginTop:4}} className="product-content installation-content">
        <Grid item xs={12} md={7}>
          <h1 style={{marginTop:"0.8em"}}>MAINTENANCE & WARRANTY</h1>
          <p>
          Depending on which membrane is selected, an appropriate membrane material warranty will be provided. Any and all workmanship warranties are established by the modular manufacturer or 3rd party installer they select.
          </p>
          
          <h2>ROOFINGSERIES PRODUCTS</h2>
          <p>
            <Link
              to={firstWarranty}
              target="_blank"
              style={{ textDecoration: "none", color: "#7fb180" }}>
              R12000 10yr. Warranty
            </Link>
          </p>
          <p>
            <Link
              to={secondWarranty}
              target="_blank"
              style={{ textDecoration: "none", color: "#7fb180" }}>
              R15000 15yr. Warranty
            </Link>
          </p>
          <p>
            <Link
              to={thirdWarranty}
              target="_blank"
              style={{ textDecoration: "none", color: "#7fb180" }}>
              R20000 20yr. Warranty
            </Link>
          </p>

          {/* <h2>EPDM PRODUCTS</h2>
          <p>
            <Link
              to="https://www.mulehide.com/productcategory/roofing-products/modular-roofing-systems/modular-epdm-systems/warranty-information"
              target="_blank"
              style={{ textDecoration: "none", color: "#7fb180" }}>
              Mulehide Warranty Information
            </Link>
          </p> */}
        </Grid>
        <Grid item xs={12} md={5}>
          <h3>
          PROVIDING MAINTENANCE ADVICE AND WARRANTIES FOR ALL OUR ROOFINGSERIES PRODUCTS. WHEN INSTALLED PROPERLY, ROOFINGSERIES MEMBRANES WILL NOT FAIL.
          </h3>
          <Box className="details">
            <div className="try">1</div>
            <p style={{padding:"0 0 1em 4em"}}>

              <em>
              <strong>10, 15 and 20 year membrane warranties available.</strong>
            </em>
            </p>
          </Box>
          <Box className="details">
            <div className="try">2</div>
            <p style={{padding:"0 0 1em 4em"}}>

<em>
              <strong>
                Customer protection and peace of mind for PVC as well as EPDM
                solutions.
              </strong>
            </em>
            </p>
          </Box>
          <Box className="details">
            <div className="try">3</div>
            <p style={{padding:"0 0 1em 4em"}}>

<em>
              <strong>
                Reliabilty without compromise, the toughest membranes on the
                market.
              </strong>
            </em>
            </p>
          </Box>

          <h3>TO GET YOUR PROJECT STARTED WITH ROOFINGSERIES MEMBRANES:</h3>
          <a href="mailto:sales@dec-tec.com" rel="media-gallery">
          <button className="lastbutton">
              Contact Us Today 
          </button>
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

export default MaintenanceWarranty;
