import { Box } from "@mui/material";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import "../assets/css/Footer.css"
import "../assets/css/Home.css"
import roofingseries from "../assets/images/roofingseries.png"
const Footer = () => {
  return (
    <div className="home-container footer">
      <Box className="footer-section">
        <img
          src={roofingseries}
          style={{ height: "50px" }}
        />
        <Box className="sitemap-section">
          <Link to="/sitemap">Sitemap</Link>
          <p>All Rights Reserved 2024 © RoofingSeries.com</p>
        </Box>
      </Box>
    </div>
  );
};

export default Footer;
