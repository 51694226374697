import LandingPage from "../components/LandingPage";
import {  Grid } from "@mui/material";
import "../assets/css/Installation.css";
import installation from "../assets/images/installation.jpg";
import { useEffect, useRef, useState } from "react";
import installationGuide from "../assets/pdf/modular-installation-guide.pdf";
import thermalHeat from "../assets/pdf/thermal-heat-welding-guidev2.pdf"
import maintenanceSuggestion from "../assets/pdf/maintenance-suggestions.pdf"
import faq from "../assets/pdf/faq.pdf"

const Installation = () => {

  const [openModal, setOpenModal] = useState(false);
  const iframeRef = useRef(null);

  // const handleOpenModal = () => {
  //   setOpenModal(true);
  // };

  const handleClickOutsideIframe = (event) => {
    if (iframeRef.current && !iframeRef.current.contains(event.target)) {
      setOpenModal(false);
    }
  }

    useEffect(() => {
      if (openModal) {
        document.addEventListener("mousedown", handleClickOutsideIframe);
      } else {
        document.removeEventListener("mousedown", handleClickOutsideIframe);
      }
      return () => {
        document.removeEventListener("mousedown", handleClickOutsideIframe);
      };
    }, [openModal]);


  return (
    <div className="home-container">
      <LandingPage heading="Installation" landingImg={installation} />
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          display: "flex",
        }}
      >
        <Grid item xs={12} md={11}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="installation-content"
          >
            <Grid item xs={12} md={7}>
              <h1>ROOFINGSERIES PVC MEMBRANE</h1>
              <p>
                To help ensure proper installation of our exclusive
                RoofingSeries PVC Membrane and Accessories, please refer to the{" "}
                <b>Installation Guide.</b> You will find best known methods of
                application, helpful tips and support to make the installation
                process easier.
              </p>
              <p>
                To further support the installation process, we have created a{" "}
                <b>Thermal Heat Welding Guide.</b> This guide will help coach
                the applicator with the use of the appropriate tools, proper
                safety precautions to be taken, heat welding techniques,
                expected outcomes, and support by way of a troubleshooting
                chart.
              </p>
              <p>
                Basic roof maintenance should be expected and planned for. This
                helps to ensure the longevity and water tightness of your
                RoofingSeries PVC system. We have made
                <b> Maintenance Suggestions </b>
                that will help anyone better assess the roof long after it has
                left the factory.
              </p>
              {/* <h1>MULEHIDE EPDM INSTALLATION INFORMATION</h1>
              <Link
                to="https://www.mulehide.com/productcategory/roofing-products/modular-roofing-systems/modular-epdm-systems/installation-videos"
                style={{ textDecoration: "none", color: "#7fb180" }}
              >
                Click here for Installation Information
              </Link> */}
            </Grid>
            <Grid item xs={12} md={5}>
             
                <a
                  href={installationGuide}
                  target="_blank"
                  rel="noreferrer"
                >
                   <button className="greenbutton">
                  PVC & EPDM Installation Guides
                  </button>
                </a>
             
             
                <a
                  href={thermalHeat}
                  target="_blank"
                  rel="noreferrer"
                >
                   <button className="greenbutton">
                  Thermal Heat Welding Guide
                  </button>
                </a>
             
              
                <a
                  href={maintenanceSuggestion}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="greenbutton">
                  Maintenance Suggestions
                  </button>
                </a>
          
              
                <a
                  href={faq}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="greenbutton">
                  Frequently Asked Questions
                  </button>
                </a>
                
               {/* <button className="lastbutton" onClick={handleOpenModal}>
                View Fire Test Video
              </button> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openModal && (
                <iframe
                  title="Fire Test Video"
                  width="80%"
                  height="60%"
                  src="https://www.youtube.com/embed/yilZgrEg6Z8"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 9999,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                    borderRadius: "8px",
                    background:"#fff",
                    padding:"10px"
                  }}
                  ref={iframeRef}
                ></iframe>
              )}
    </div>

    
  );
};

export default Installation;
