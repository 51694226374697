import { Button, Grid, TextField } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import LandingPageSmall from "../components/LandingPageSmall";
import contact from "../assets/images/conact.jpg";
import "../assets/css/Contact.css"

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    reason: "",
    comments: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const baseUrl = "https://tsmiscwebapi-staging.azurewebsites.net";

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const newErrorMessages = {};

    if (!formData.name) {
      newErrorMessages.name = "Please enter your first name.";
    }

    if (!formData.phone) {
      newErrorMessages.phone = "Please enter your contact number.";
    }

    if (!formData.email) {
      newErrorMessages.email = "Please enter your email.";
    }

    if (Object.keys(newErrorMessages).length > 0) {
      setErrorMessages(newErrorMessages);
    } else {
      console.log(formData);

      const obj = {
        UserId: 0,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            Name: formData.name,
            EmailAddress: formData.email,
            Phone: formData.phone,
            CompanyName: formData.company,
            ReasonforEnquiry: formData.reason,
            Comments: formData.comments,
          },
        ],
      };

      await axios
        .post(`${baseUrl}/api/UserInformation/SaveContactUs`, obj)
        .then(async (res) => {
          const id = res?.data?.data?.[0]?.objData?.id ?? 0;
          alert("Email send successfully");
              console.log("Email send successfully", res);
          if (!id) return null;
        })
        .catch((err) => {
          console.log("error while sending data :: ", err);
        });
      setFormData({
        name: "",
        phone: "",
        email: "",
        company: "",
        reason: "",
        comments: "",
      });
      setErrorMessages({
        name: "",   
        email: "",
        phone: "",
      });
    }
  };
  return (
    <div className="home-container">

      <LandingPageSmall landingImg={contact} heading="CONTACT" />

      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          display: "flex",
          marginTop: "5%",
        }}
        className="installation-content contact-content">
        <Grid item xs={12} sm={12} md={11}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6}>
              <h1>CONTACT INFORMATION</h1>
              <p>
                261185 Wagon Wheel Way
                <br />
                Rocky View, Alberta, Canada
                <br />
                T4A 0E2
                <br />
                Tel: 403-250-5507
                <br />
                Toll-Free: 1-866-461-3914
                {/* <br />
                Fax: 1-403-398-9910 */}
              </p>
              <p>
                We are always open to Questions, Comments, or Feedback and thank
                you for taking the time to reach out to us. You may either call
                us, or use the form to get your message to us. We will respond
                in a timely fashion.
              </p>
            </Grid>
            <Grid item xs={12}  md={6}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}>
                <Grid container spacing={2} className="contact-form">
                  <Grid item xs={12}>
                    <div className="contact-input-title"> Name<span> *</span></div>                 
                    <TextField
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      fullWidth
                    />
                    {errorMessages.name && (
                      <div style={{ color: "red" }}>{errorMessages.name}</div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                  <div className="contact-input-title">  Email Address<span> *</span></div>    
                    <TextField
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      fullWidth
                    />
                    {errorMessages.email && (
                      <div style={{ color: "red" }}>{errorMessages.email}</div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                  <div className="contact-input-title">  Phone<span> *</span></div>  
                    
                    <TextField
                      name="phone"
                      type="tel"
                      value={formData.phone}
                      onChange={handleInputChange}
                      fullWidth
                    />
                    {errorMessages.email && (
                      <div style={{ color: "red" }}>{errorMessages.phone}</div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    
                  <div className="contact-input-title">Company Name</div>  

                    <TextField
                      name="company"
                      value={formData.company}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <div className="contact-input-title">Reason for Inquiry</div>  

                    <TextField
                      name="reason"
                      value={formData.reason}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <div className="contact-input-title">Comments</div>  

                    <TextField
                      name="comments"
                      multiline
                      rows={4}
                      value={formData.comments}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      className="myBtn"
                      fullWidth>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;
