import { Box } from "@mui/system";
import LandingPageSmall from "../components/LandingPageSmall";
import { Grid } from "@mui/material";
import "../assets/css/Header.css";
import RoofSeriesAdvantage from "../components/RoofSeriesAdvantage";
import support3 from "../assets/images/support3.jpg";

const SiteSupportInstallation = () => {
  return (
    <div className="home-container">
      <LandingPageSmall
        landingImg={support3}
        heading="Site Support & Tie-In Installations"
      />

      <RoofSeriesAdvantage />

      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginTop: 4 }}
        className="installation-content"
      >
        <Grid item xs={12} md={7}>
          <h2>Site Support & Tie-In Installations</h2>
          <p>
            We want to help ensure that what was done in the factory is equally
            as good as onsite. RoofingSeries can answer any questions concerning
            membranes or the installation process. We have a growing network of
            qualified roofing contractors that we can refer you to and be able
            to assist in getting what you need, peace of mind when it comes to
            the water tightness of your roofing system. From double wides to
            multiple units to every roof system, we can support by way of our
            networking and knowledge of capable installing contractors that we
            can refer to come to you with industry specific knowledge and proven
            experience in the installation of the various roof membrane types.
          </p>
        </Grid>
        <Grid item xs={12} md={5}>
          <h3>
            PROVIDING THE PEACE OF MIND THAT WHAT WAS DONE IN PLANT IS NOT BEING
            COMPROMISED. RATHER IT IS BEING COMPLETED, PROPERLY.
          </h3>
          <Box className="details">
            <div className="try">1</div>
            <p style={{ padding: "0 0 1em 4em" }}>
              <em>
                <strong>
                  Consider the location the modular units are to be site
                  assembled.
                </strong>
              </em>
            </p>
          </Box>
          <Box className="details">
            <div className="try">2</div>
            <p style={{ padding: "0 0 1em 4em" }}>
              <em>
                <strong>
                  Consider if you need to hire a roofing contractor to finish
                  what began at factory.
                </strong>
              </em>
            </p>
          </Box>
          <Box className="details">
            <div className="try">3</div>
            <p style={{ padding: "0 0 1em 4em" }}>
              <em>
                <strong>
                  Consider what timeline you need to have the roof tied in by.
                </strong>
              </em>
            </p>
          </Box>

          <h3>
            TO HAVE US HELP PROVIDE RELIABLE, KNOWLEDGEABLE AND PROVEN
            CONTRACTOR SUPPORT:
          </h3>
          <a href="mailto:sales@dec-tec.com" rel="media-gallery">
            <button className="lastbutton">Contact Us Today</button>
          </a>
          <a href="/support/maintenance-warranty" rel="media-gallery">
            <button className="lastbutton">
              NEXT – MAINTENANCE & WARRANTY
            </button>
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

export default SiteSupportInstallation;
